import React, { useEffect } from 'react';
import { ThemeProvider } from 'react-jss';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import Cookiebot from 'react-cookiebot';

import store from './store';

import Home from './routes/Home';
import Project from './routes/Project';
import Privacy from './routes/Privacy';

const theme = {
  colors: {
    // Bright colors
    brightPrimary: '#ff6164',
    brightSecondary: '#ff8264',
    brightTertiary: '#ffa264',
    // Dark colors
    darkPrimary: '#0f1419',
    darkSecondary: '#181e29',
    darkTertiary: '#273042',
    darkQuaternary: '#3a4861',
    // Text colors
    textPrimary: '#ffffff',
  },
};

function App() {
  useEffect(() => {
    // Initialize Houdini paint worklet
    if ('paintWorklet' in CSS) {
      CSS.paintWorklet.addModule('worklets/inverted-corners.js');
    } else {
      console.warn('Houdini\'s Paint Worklet is not supported on your browser.');
    }

    // Initialize Hotjar
    hotjar.initialize(2189623, 6);
  }, []);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/projects/:slug" component={Project} />
            <Route path="/privacy-policy" component={Privacy} />
          </Switch>
        </BrowserRouter>
        <Cookiebot domainGroupId="44a6503c-9ddd-4388-b302-28b4d492058e" />
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
