import React from 'react';

import { ReactComponent as DockerIcon } from 'assets/images/technologies/docker.svg';
import { ReactComponent as FirebaseIcon } from 'assets/images/technologies/firebase.svg';
import { ReactComponent as JavaScriptIcon } from 'assets/images/technologies/javascript.svg';
import { ReactComponent as NodeIcon } from 'assets/images/technologies/nodejs.svg';
import { ReactComponent as PHPIcon } from 'assets/images/technologies/php.svg';
import { ReactComponent as PythonIcon } from 'assets/images/technologies/python.svg';
import { ReactComponent as ReactIcon } from 'assets/images/technologies/react.svg';
import { ReactComponent as SocketIOIcon } from 'assets/images/technologies/socketio.svg';
import { ReactComponent as WebRTCIcon } from 'assets/images/technologies/webrtc.svg';
import { ReactComponent as WordPressIcon } from 'assets/images/technologies/wordpress.svg';

const icons = {
  docker: DockerIcon,
  firebase: FirebaseIcon,
  javascript: JavaScriptIcon,
  nodejs: NodeIcon,
  php: PHPIcon,
  python: PythonIcon,
  react: ReactIcon,
  socketio: SocketIOIcon,
  webrtc: WebRTCIcon,
  wordpress: WordPressIcon,
};

function IconComponent({ name, ...props }) {
  const Icon = icons[name];
  return <Icon {...props} />
}

export default IconComponent;
