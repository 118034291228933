import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Social from './Social';
import Logo from './Logo';

import EmailIcon from 'assets/images/social/email.svg';
import MastodonIcon from 'assets/images/social/mastodon.svg';
import GitHubIcon from 'assets/images/social/github.svg';
import StackOverflowIcon from 'assets/images/social/stackoverflow.svg';
import WordPressIcon from 'assets/images/social/wordpress.svg';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '40px',
  },
  name: {
    fontVariationSettings: `'wght' 300`,
    fontSize: '3em',
    fontStretch: '100%',
    color: ({ theme }) => theme.colors.textPrimary,
    marginTop: '0.2em',
    // backgroundImage: ({ theme }) => `-webkit-linear-gradient(${theme.colors.brightTertiary}, ${theme.colors.brightPrimary})`,
  },
  title: {
    fontVariationSettings: `'wght' 700`,
    color: ({ theme }) => theme.colors.brightSecondary,
    textTransform: 'uppercase',
    letterSpacing: '0.2em',
    margin: 0,
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // gap: '1em',
    marginTop: '1.4em',
  },
});

function Header() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.header}>
      <Logo style={{ width: '120px' }} />

      <h1 className={classes.name}>
        over-engineer
      </h1>

      <p className={classes.title}>
        Software Developer
      </p>

      <div className={classes.socials}>
        <Social
          label="dev@over-engineer.com"
          url="mailto:dev@over-engineer.com"
          icon={EmailIcon}
        />

        <Social
          label="@overengineer@fosstodon.org"
          url="https://fosstodon.org/@overengineer"
          icon={MastodonIcon}
        />

        <Social
          label="@over-engineer"
          url="https://github.com/over-engineer"
          icon={GitHubIcon}
        />

        <Social
          label="@over-engineer"
          url="https://stackoverflow.com/users/11722843/over-engineer"
          icon={StackOverflowIcon}
        />

        <Social
          label="@overengineer"
          url="https://profiles.wordpress.org/overengineer/"
          icon={WordPressIcon}
        />
      </div>
    </div>
  );
}

export default Header;
