import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '2.2rem',
    color: ({ theme }) => theme.colors.brightSecondary,
    marginTop: '60px',
    backgroundColor: ({ theme }) => theme.colors.darkSecondary,
    maskImage: 'paint(inverted-corners)',
    '-webkit-mask-image': 'paint(inverted-corners)',
    '--corner-radius': '30 30 -30 -30',
    padding: '0.5em 2em 0.3em 2em',
    textAlign: 'center',
    borderRadius: 'paintWorklet' in CSS ? '0' : '30px 30px 0 0',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
    gap: '1em',
    listStyleType: 'none',
    padding: '20px',
    width: 'calc(100% - 40px)',
    maxWidth: '850px',
    backgroundColor: ({ theme }) => theme.colors.darkSecondary,
    borderRadius: '30px',
    margin: 0,
  },
  project: {
    textAlign: 'center',
    padding: '10px',
    transition: 'background-color .6s ease-in-out',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: ({ theme }) => theme.colors.darkTertiary,
      transition: 'background-color .1s ease-out',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  icon: {
    width: '100px',
  },
  projectName: {
    color: ({ theme }) => theme.colors.textPrimary,
    fontVariationSettings: `'wght' 600`,
    marginTop: '0.6em',
  },
});

const images = require.context('../assets/images/projects', true);

const projectContainer = {
  hidden: {
    opacity: 1,
    scale: 1,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariants = {
  hidden: { opacity: 0, scale: 0 },
  show: { opacity: 1, scale: 1 },
};

function Projects() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const projects = useStoreState((state) => state.projects.main);

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>
        Projects
      </h2>
      <motion.ul
        className={classes.grid}
        variants={projectContainer}
        initial="hidden"
        animate="show"
      >
        {projects.map((project, index) => {
          const icon = images(`./${project.icon}`).default;

          return (
            <motion.li
              key={`project-${index}`}
              className={classes.project}
              variants={projectVariants}
            >
              <Link
                to={`/projects/${project.slug}`}
                className={classes.link}
              >
                {project.icon ? <img src={icon} alt={project.name} className={classes.icon} /> : null}
                <span className={classes.projectName}>
                  {project.name}
                </span>
              </Link>
            </motion.li>
          );
        })}
      </motion.ul>
    </div>
  );
}

export default Projects;
