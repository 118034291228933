import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState } from 'easy-peasy';

import Icon from 'components/Icon';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '2.2rem',
    color: ({ theme }) => theme.colors.brightSecondary,
    marginTop: '60px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
    gap: '1em',
    listStyleType: 'none',
    padding: 0,
    width: '100%',
    maxWidth: '850px',
  },
  tech: {
    textAlign: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    // gap: '12px',
  },
  techName: {
    color: ({ theme }) => theme.colors.textPrimary,
    fontVariationSettings: `'wght' 600`,
    marginTop: '12px',
  },
});

function Technologies() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const technologies = useStoreState((state) => state.tech.main);

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>
        Technologies
      </h2>
      <ul
        className={classes.grid}
      >
        {technologies.map((tech, index) => {
          return (
            <li
              key={`tech-${index}`}
              className={classes.tech}
            >
              <a
                href={tech.url}
                rel="noreferrer"
                target="_blank"
                title={tech.name}
                className={`hover-link ${classes.link}`}
              >
                {tech.icon ? <Icon name={tech.icon} style={{ width: '60px' }} /> : null}
                <span className={classes.techName}>
                  {tech.name}
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Technologies;
