import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { motion } from 'framer-motion';

const useStyles = createUseStyles({
  path: {
    fill: 'none',
    stroke: '#fff',
    strokeWidth: 6,
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeDasharray: 690,
  },
});

function Logo(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <motion.svg
      x="0px"
      y="0px"
      viewBox="0 0 138.3 138.3"
      xmlSpace="preserve"
      className={classes.logo}
      {...props}
    >
      <motion.path
        className={classes.path}
        initial={{ strokeDashoffset: 690 }}
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 0.4, delay: 1, ease: 'easeIn' }}
        d="M134.6,77c-3.7,33-31.4,58.3-65.4,58.3C32.6,135.3,3,105.7,3,69.2S32.6,3,69.2,3s66.2,29,66.2,65.5v0.6             c0,22.3-18.4,40.3-41.1,40.3S53.1,91.8,53.1,69.6l0-0.4c0-14,11.3-25.3,25.3-25.3s25.3,11.3,25.3,25.3S92.3,94.4,78.4,94.4             c-11.5,0-21.6-7.6-24.7-18"
      />
    </motion.svg>
  );
}

export default Logo;
