import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    margin: '0 0.5em',
    '&:hover': {
      opacity: 0.6,
    },
  },
  icon: {
    width: '2.4em',
  },
});

function Social(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { label, url, icon, rel } = props;

  return (
    <a
      href={url}
      rel="noreferrer"
      target="_blank"
      title={label}
      className={classes.link}
    >
      <img src={icon} alt={label} className={classes.icon} />
    </a>
  );
}

export default Social;
