import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { motion } from 'framer-motion';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  playground: {
    maxWidth: '100%',
    width: '840px',
    height: '640px',
    border: 'none',
    flex: 1,
  },
});

const playgroundContainer = {
  hidden: { opacity: 0, scale: 0 },
  show: { opacity: 1, scale: 1 },
};

function Playground({ playgroundOptions }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const options = {
    mode: playgroundOptions.mode || 'seamless',
    login: playgroundOptions.login || '1',
    page: playgroundOptions.page || '/wp-admin/',
    theme: playgroundOptions.theme || null,
    rpc: playgroundOptions.rpc || null,
  };

  Object.keys(options).forEach(key => options[key] === null && delete options[key]);

  const query = Object.keys(options).map(key => `${key}=${options[key]}`).join('&');
  let url = `https://wasm.wordpress.net/wordpress.html?${query}`;

  if (playgroundOptions.plugin) {
    const { plugin } = playgroundOptions;
    const plugins = Array.isArray(plugin) && plugin.length > 1 ? plugin : [plugin];

    url = `${url}&plugin=${plugins.join('&plugin=')}`;
  }

  return (
    <div className={classes.container}>
      <motion.div
        className={classes.grid}
        variants={playgroundContainer}
        initial="hidden"
        animate="show"
      >
        <iframe
          className={classes.playground}
          title="WordPress playground"
          src={url}
        ></iframe>
      </motion.div>
    </div>
  );
}

export default Playground;
