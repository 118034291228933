import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactStars from 'react-rating-stars-component';

import Icon from 'components/Icon';
import Playground from 'components/Playground';

const useStyles = createUseStyles({
  wrapper: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '40px',
  },
  icon: {
    width: '120px',
  },
  projectName: {
    fontVariationSettings: `'wght' 300`,
    fontSize: '3em',
    fontStretch: '100%',
    color: ({ theme }) => theme.colors.textPrimary,
    marginTop: '0.2em',
    textAlign: 'center',
  },
  description: {
    fontVariationSettings: `'wght' 700`,
    color: ({ theme }) => theme.colors.brightSecondary,
    letterSpacing: '0.2em',
    margin: 0,
    textAlign: 'center',
  },
  stack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 80px)',
    justifyContent: 'center',
    gap: '1em',
    listStyleType: 'none',
    padding: 0,
    width: '100%',
    maxWidth: '850px',
  },
  heading: {
    fontSize: '2rem',
    color: ({ theme }) => theme.colors.brightSecondary,
  },
  tech: {
    textAlign: 'center',
  },
  techLink: {
    textDecoration: 'none',
  },
  techName: {
    color: ({ theme }) => theme.colors.textPrimary,
    fontVariationSettings: `'wght' 600`,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: ({ theme }) => theme.colors.darkSecondary,
    borderRadius: '30px',
    padding: '20px',
    width: 'calc(100% - 40px)',
    maxWidth: '850px',
  },
  downloadsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  downloads: {
    fontSize: '2rem',
    fontVariationSettings: `'wght' 800`,
    color: ({ theme }) => theme.colors.textPrimary,
  },
  downloadsLabel: {
    fontSize: '1rem',
    color: ({ theme }) => theme.colors.textPrimary,
  },
  releaseDate: {
    fontSize: '2rem',
    color: ({ theme }) => theme.colors.brightSecondary,
  },
  link: {
    textDecoration: 'none',
    color: ({ theme }) => theme.colors.textPrimary,
    backgroundColor: ({ theme }) => theme.colors.darkTertiary,
    padding: '0.6em 1.2em',
    lineHeight: '1em',
    borderRadius: '9999px',
    margin: '40px 0',
    '&:hover': {
      backgroundColor: ({ theme }) => theme.colors.brightSecondary,
      color: ({ theme }) => theme.colors.darkPrimary,
    },
  },
});

const images = require.context('../assets/images/projects', true);

function Project(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const projects = useStoreState((state) => state.projects.main);

  const slug = props.match.params.slug;
  const project = projects.find((project) => project.slug === slug);

  const icon = images(`./${project.icon}`).default;

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {project.icon ? <img src={icon} alt={project.name} className={classes.icon} /> : null}
        <h1 className={classes.projectName}>
          {project.name}
        </h1>
        {project.description ? (
          <p className={classes.description}>
            {project.description}
          </p>
        ) : null}
      </div>

      <a
        href={project.url}
        rel="noreferrer"
        target="_blank"
        className={classes.link}
      >
        {project.shortUrl}
      </a>

      <div className={classes.details}>
        {project.rating ? (
          <ReactStars
            count={5}
            value={project.rating}
            size={24}
            isHalf={true}
            activeColor={theme.colors.brightTertiary}
            emptyIcon={<FontAwesomeIcon icon="star" />}
            halfIcon={<FontAwesomeIcon icon="star-half-alt" />}
            fullIcon={<FontAwesomeIcon icon="star" />}
            edit={false}
          />
        ) : null}

        {project.downloads || project.activeInstallations ? (
          <p className={classes.downloadsContainer}>
            <span className={classes.downloads}>
              {project.downloads ? project.downloads : project.activeInstallations}
            </span>
            <span className={classes.downloadsLabel}>
            {project.downloads ? 'downloads' : 'active installations'}
            </span>
          </p>
        ) : null}

        {project.playground ? (
          <Playground playgroundOptions={project.playground} />
        ) : null}

        {project.stack ? (
          <div className={classes.stack}>
            <h2 className={classes.heading}>
              Tech Stack
            </h2>
            
            <ul className={classes.grid}>
              {project.stack.map((tech, index) => {
                return (
                  <li className={classes.tech} key={`tech-${index}`}>
                    <a
                      href={tech.url}
                      rel="noreferrer"
                      target="_blank"
                      title={tech.name}
                      className={`hover-link ${classes.techLink}`}
                    >
                      {tech.icon ? <Icon name={tech.icon} /> : null}
                      <span className={classes.techName}>
                        {tech.name}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Project;
