import { createStore } from 'easy-peasy';

import projects from './models/projects';
import tech from './models/technologies';

const storeModel = {
  projects,
  tech,
};

export default createStore(storeModel);
