const techModel = {
  main: [
    {
      name: 'JavaScript',
      icon: 'javascript',
      url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript',
    },
    {
      name: 'Node.js',
      icon: 'nodejs',
      url: 'https://nodejs.org/',
    },
    {
      name: 'PHP',
      icon: 'php',
      url: 'https://www.php.net/',
    },
    {
      name: 'Python',
      icon: 'python',
      url: 'https://www.python.org/',
    },
    {
      name: 'WordPress',
      icon: 'wordpress',
      url: 'https://wordpress.org/',
    },
    {
      name: 'React',
      icon: 'react',
      url: 'https://reactjs.org/',
    },
    {
      name: 'socket.io',
      icon: 'socketio',
      url: 'https://socket.io/',
    },
    {
      name: 'WebRTC',
      icon: 'webrtc',
      url: 'https://webrtc.org/',
    },
    {
      name: 'Docker',
      icon: 'docker',
      url: 'https://www.docker.com/',
    },
  ],
};

export default techModel;
