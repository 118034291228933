import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  wrapper: {
    padding: '20px',
    color: '#8e99af',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '850px',
  },
  heading: {
    color: ({ theme }) => theme.colors.textPrimary,
    marginTop: '2em',
  },
  link: {
    color: ({ theme }) => theme.colors.brightSecondary,
    textDecoration: 'none',
  },
});

function Privacy() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <h1 className={classes.heading}>
          Privacy Policy
        </h1>
        <p><strong>Effective date: April 2, 2023</strong></p>

        <p>Pappas Konstantinos (&quot;developer&quot;, &quot;I&quot;, &quot;me&quot;, or &quot;my&quot;) built the <strong>over-engineer.com</strong> website (the &quot;website&quot;, &quot;site&quot;, &quot;app&quot;, or &quot;service&quot;). This app is provided by Pappas Konstantinos and is intended for use as is.</p>
        <p>Any reference in this Privacy Policy to the &quot;user&quot;, &quot;you&quot;, or &quot;your&quot; refers to you, the user of the app and any reference to the developer, refers to me.</p>
        <p>This Privacy Policy has been compiled to provide you with clear and complete information about which personal data I gather and how you can view and control its handling.</p>
        <p>I will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <h2 className={classes.heading} id="who-i-am">Who I am</h2>
        <p>I am Pappas Konstantinos, developer and owner of the <strong>over-engineer.com</strong> website.</p>
        <p>Email address: <a href="mailto:dev@over-engineer.com" className={classes.link}>dev@over-engineer.com</a></p>

        <h2 className={classes.heading} id="what-personal-data">What personal data I collect</h2>
        <p><strong>I do not</strong> collect, store, and/or process any personal data, <strong>with the exception of <a href="https://www.hotjar.com/legal/policies/privacy/" className={classes.link} rel="noreferrer" target="_blank">Hotjar</a></strong>.</p>
        <p>I use Hotjar in order to better understand my users’ needs and to optimize this service and experience. Hotjar is a technology service that helps me better understand my users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables me to build and maintain my service with user feedback. Hotjar uses cookies and other technologies to collect data on my users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display my website. Hotjar stores this information on my behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on my behalf.</p>

        <h2 className={classes.heading} id="analytics">Analytics</h2>
        <p>I use <a href="https://plausible.io/" className={classes.link} rel="noreferrer" target="_blank">Plausible Analytics</a> on my website to collect information about how visitors interact with my site. Plausible is a web analytics tool that provides website owners with aggregated data about visitor behavior, including metrics such as page views, bounce rates, and session durations. <strong>Plausible does not collect any personal information about website visitors and does not use cookies to track visitor activity</strong>.</p>
        <p>All the site measurement is carried out absolutely <strong>anonymously</strong>. <strong>Cookies are not used</strong> and <strong>no personal data is collected</strong>. There are <strong>no persistent identifiers</strong>. <strong>No cross-site or cross-device tracking</strong> either. Plausible is fully compliant with GDPR, CCPA and PECR.</p>
        <p>Also, I do not use the managed (cloud) service of Plausible, I <strong>self-host</strong> their open source version through Docker on my VPS instead.</p>

        <h2 className={classes.heading} id="who-i-share-your-data-with">Who I share your data with</h2>
        <p>I don’t share your data with third-parties in a way as to reveal any of your personal information like email, name, etc. However, some data is transferred and/or stored with Hotjar, which is a third-party service I use.</p>

        <h2 className={classes.heading} id="how-long-i-retain-your-data">How long I retain your data</h2>
        <p>I do not retain any personally identifiable information on my server (excluding any log files used exclusively for debugging purposes). Some data might be retained on Hotjar.</p>
        <p>In case you want me to erase any personal data I hold about you, you can request it via an email to <a href="mailto:dev@over-engineer.com" className={classes.link}>dev@over-engineer.com</a> (for information regarding your rights, refer to the &quot;What rights you have over your data&quot; section).</p>

        <h2 className={classes.heading} id="what-rights-you-have-over-your-data">What rights you have over your data</h2>
        <p>If you are located in certain countries, including those that fall under the scope of the European General Data Protection Regulation (GDPR), data protection laws give you rights I respect to your personal data, subject to any exemptions provided by the law, including the rights to:</p>
        <ul>
            <li>
                <strong>Right of Access</strong>
                <p>The right to be informed of and request access to the personal data I process about you.</p>
            </li>
            <li>
                <strong>Right to Rectification</strong>
                <p>The right to request that I amend or update your personal data I process about you.</p>
            </li>
            <li>
                <strong>Right to Erasure</strong>
                <p>The right to request that I delete your personal data.</p>
            </li>
            <li>
                <strong>Right to Restrict</strong>
                <p>The right to request that I temporarily or permanently stop processing all or some of your personal data.</p>
            </li>
            <li>
                <strong>Right to Object</strong>
                <ul>
                    <li>The right to request that I temporarily or permanently stop processing all or some of your personal data.</li>
                    <li>The right to object to your personal data being processed for direct marketing purposes.</li>
                </ul>
            </li>
        </ul>
        <p>For these requests, please contact me at <a href="mailto:dev@over-engineer.com" className={classes.link}>dev@over-engineer.com</a></p>

        <h2 className={classes.heading} id="children-privacy">Children’s Privacy</h2>
        <p>I do not knowingly collect personally identifiable information from children under 18. If you are a parent or guardian and you are aware that your child has provided me with personal information, please contact me so that I will be able to do necessary actions.</p>

        <h2 className={classes.heading} id="links-to-other-sites">Links to other sites</h2>
        <p>My app may contain links to other sites that are not operated by me (for example, my profiles on <a href="https://docs.github.com/en/free-pro-team@latest/github/site-policy/github-privacy-statement" className={classes.link} rel="noreferrer" target="_blank">GitHub</a>, <a href="https://stackoverflow.com/legal/privacy-policy" className={classes.link} rel="noreferrer" target="_blank">Stack Overflow</a>, and <a href="https://wordpress.org/about/privacy/" className={classes.link} rel="noreferrer" target="_blank">WordPress</a>). If you click on a third-party link, you will be directed to that third-party’s site. I strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>I have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

        <h2 className={classes.heading} id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h2>
        <p>I may update my Privacy Policy from time to time at my sole discretion. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page and updating the &quot;effective date&quot; at the top of this page. These changes are effective immediately after they are posted on this page.</p>

        <h2 className={classes.heading} id="how-to-contact-me">How to contact me</h2>
        <p>If you have any questions about my Privacy Policy, do not hesitate to contact me at <a href="mailto:dev@over-engineer.com" className={classes.link}>dev@over-engineer.com</a></p>
      </div>
    </div>
  );
}

export default Privacy;
