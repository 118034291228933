const projectsModel = {
  main: [
    {
      name: 'Link Verification for Mastodon',
      year: '2022',
      description: 'An unofficial WordPress plugin to quickly verify a link on your Mastodon profile.',
      icon: 'link-verification-for-mastodon.svg',
      slug: 'link-verification-for-mastodon',
      stack: [
        { name: 'WordPress', icon: 'wordpress', url: 'https://wordpress.org/' },
        { name: 'PHP', icon: 'php', url: 'https://www.php.net/' },
      ],
      rating: 5,
      activeInstallations: '600+',
      url: 'https://github.com/over-engineer/link-verification-for-mastodon',
      shortUrl: 'github.com/over-engineer/link-verification-for-mastodon',
      playground: {
        plugin: 'link-verification-for-mastodon',
        page: '/wp-admin/admin.php?page=link-verification-for-mastodon',
      },
    },
    {
      name: 'Inverted Corners',
      year: '2021',
      description: 'A Houdini Paint worklet for inverted corners.',
      icon: 'inverted-corners.svg',
      slug: 'inverted-corners',
      stack: [
        { name: 'JavaScript', icon: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript' },
      ],
      url: 'https://inverted-corners.netlify.app/',
      shortUrl: 'inverted-corners.netlify.app',
    },
    {
      name: 'Chillmate',
      year: '2020-2023',
      description: 'A P2P group video call and instant messaging app.',
      icon: 'chillmate.png',
      slug: 'chillmate',
      stack: [
        { name: 'JavaScript', icon: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript' },
        { name: 'React', icon: 'react', url: 'https://reactjs.org/' },
        { name: 'WebRTC', icon: 'webrtc', url: 'https://webrtc.org/' },
        { name: 'Node.js', icon: 'nodejs', url: 'https://nodejs.org/', },
        { name: 'socket.io', icon: 'socketio', url: 'https://socket.io/', },
        { name: 'Firebase', icon: 'firebase', url: 'https://firebase.google.com/' },
      ],
      url: 'https://chillmate.app/',
      shortUrl: 'chillmate.app',
    },
    {
      name: 'Peasant',
      year: '2020',
      description: 'A multiplayer card game.',
      icon: 'peasant.svg',
      slug: 'peasant',
      stack: [
        { name: 'JavaScript', icon: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript' },
        { name: 'React', icon: 'react', url: 'https://reactjs.org/' },
        { name: 'Node.js', icon: 'nodejs', url: 'https://nodejs.org/', },
        { name: 'socket.io', icon: 'socketio', url: 'https://socket.io/', },
        { name: 'Firebase', icon: 'firebase', url: 'https://firebase.google.com/' },
      ],
      url: 'https://peasant.icyhippo.com/',
      shortUrl: 'peasant.icyhippo.com',
    },
    {
      name: 'Animentor',
      year: '2020',
      description: 'Lottie & Bodymovin widget for Elementor.',
      icon: 'animentor.svg',
      slug: 'animentor',
      stack: [
        { name: 'WordPress', icon: 'wordpress', url: 'https://wordpress.org/' },
        { name: 'PHP', icon: 'php', url: 'https://www.php.net/' },
        { name: 'JavaScript', icon: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript' },
      ],
      rating: 5,
      activeInstallations: '10,000+',
      url: 'https://github.com/over-engineer/animentor',
      shortUrl: 'github.com/over-engineer/animentor',
    },
    {
      name: 'Simple Plugin for Google Analytics',
      year: '2019',
      description: 'An unofficial WordPress plugin for Google Analytics.',
      icon: 'gasp.svg',
      slug: 'gasp',
      stack: [
        { name: 'WordPress', icon: 'wordpress', url: 'https://wordpress.org/' },
        { name: 'PHP', icon: 'php', url: 'https://www.php.net/' },
      ],
      url: 'https://github.com/over-engineer/gasp',
      shortUrl: 'github.com/over-engineer/gasp',
      playground: {
        plugin: 'overengineer-gasp',
        page: '/wp-admin/admin.php?page=gasp',
      },
    },
    {
      name: 'Exchange Rates',
      year: '2019',
      description: 'Node.js wrapper for exchangeratesapi.io',
      icon: 'exchange-rates.svg',
      slug: 'exchange-rates',
      stack: [
        { name: 'Node.js', icon: 'nodejs', url: 'https://nodejs.org/', },
      ],
      url: 'https://github.com/over-engineer/exchange-rates',
      shortUrl: 'github.com/over-engineer/exchange-rates',
    },
    {
      name: 'SocketIO whiteboard',
      year: '2016-2020',
      description: 'HTML5 canvas real-time drawing using socket.io',
      icon: 'socketio-whiteboard.svg',
      slug: 'socketio-whiteboard',
      stack: [
        { name: 'JavaScript', icon: 'javascript', url: 'https://developer.mozilla.org/en-US/docs/Glossary/JavaScript' },
        { name: 'Node.js', icon: 'nodejs', url: 'https://nodejs.org/', },
        { name: 'socket.io', icon: 'socketio', url: 'https://socket.io/' },
      ],
      url: 'https://github.com/over-engineer/socket.io-whiteboard',
      shortUrl: 'github.com/over-engineer/socket.io-whiteboard',
    },
    {
      name: 'Palermo',
      year: '2014',
      icon: 'palermo.svg',
      slug: 'palermo',
      rating: 4.5,
      downloads: '500,000+',
      url: 'https://icyhippo.com/apps/palermo',
      shortUrl: 'icyhippo.com/apps/palermo',
    },
  ],
};

export default projectsModel;
