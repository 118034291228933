import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Header from 'components/Header';
import Projects from 'components/Projects';
import Technologies from 'components/Technologies';

const useStyles = createUseStyles({
  wrapper: {
    padding: '20px',
  },
  footer: {
    textAlign: 'center',
    marginTop: '60px',
  },
  privacy: {
    color: ({ theme }) => theme.colors.darkQuaternary,
    textDecoration: 'none',
    '&:hover': {
      color: ({ theme }) => theme.colors.brightPrimary,
    },
  },
});

function Home() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.wrapper}>
      <Header />
      <Projects />
      <Technologies />
      <footer className={classes.footer}>
        <a
          href="/privacy-policy"
          target="_blank"
          className={classes.privacy}
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
}

export default Home;
